export const version = '1.0.0';
export const navbarBreakPoint = 'xl';

export const jwtIssuer = 'glide-platform.co.uk';

export const hours = ['', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22'];
export const minutesStep5 = ['', '00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const durations = ['', '30', '45', '50', '60', '90', '120'];
export const lanes = ['', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
export const lanesPrivate = ['', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
export const seats = ['2', '3', '6', '9', '12'];
export const tickets = [
  '1/3|1 pax - 1 row',
  '2/3|2 pax - 1 row',
  '3/3|3 pax - 1 row',
  '4/3|4 pax - 1 row',
  '2/6|2 pax - half boat',
  '3/6|3 pax - half boat',
  '4/6|4 pax - half boat',
  '5/6|5 pax - half boat',
  '6/6|6 pax - half boat',
  '7/6|7 pax - half boat',
  '7/9|7 pax - 3 rows',
  '8/9|8 pax - 3 rows',
  '9/9|9 pax - 3 rows',
  '10/9|10 pax - 3 rows',
  '6/12|PVT 6',
  '12/12|PVT 12',
];
// Add back after COVID
//export const seats = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export const today = () => {
  switch (getEnvironment()) {
    case environments.Localhost:
    default:
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }
};

export const claims = {
  SysAdmin: 'SysAdmin',
  SourceAdmin: 'SourceAdmin',
  MOTDAdmin: 'MOTDAdmin',
  UserAdmin: 'UserAdmin',
  WorkRecordAdmin: 'WorkRecordAdmin',
  BoatAdmin: 'BoatAdmin',
  Chauffeur: 'Chauffeur',
  BookingAdmin: 'BookingAdmin',
  Dashboard: 'Dashboard',
  Tout: 'Tout',
  Board: 'Board',
  AnyDay: 'AnyDay',
  DailyNotes: 'DailyNotes',
  BoatCreate: 'BoatCreate',
  StrideManager: 'StrideManager',
  StrideGuide: 'StrideGuide',
  DayReport: 'DayReport',
  HidePrice: 'HidePrice',
  StrideTout: 'StrideTout',
  SeeToutedPrice: 'SeeToutedPrice',
  MillPondManager: 'MillPondManager',
  MillPondGuide: 'MillPondGuide',
  MillPondTout: 'MillPondTout',
};

export const environments = {
  Localhost: 'Localhost',
  Development: 'Development',
  Test: 'Test',
  Production: 'Production',
};

export const getEnvironment = () => {
  const hostname = window && window.location && window.location.hostname;

  switch (hostname) {
    case 'localhost':
      return environments.Localhost;

    case 'app.test.glide-platform.co.uk':
      return environments.Test;

    case 'app.glide-platform.co.uk':
    case 'app2.glide-platform.co.uk':
      return environments.Production;

    default:
      return environments.Development;
  }
};

export const authExpiryCookieName = () => {
  const environment = getEnvironment()
    .replace('Production', '')
    .toLowerCase();
  return `api${environment && `.${environment}`}.authexpiry`;
};

export const avatarUrl = () => {
  switch (getEnvironment()) {
    case environments.Test:
      return 'https://tpuntpteststorage.blob.core.windows.net/staff-avatars/';
    case environments.Production:
      return 'https://tpuntpprodstorage.blob.core.windows.net/staff-avatars/';
    //Dev & LocalHost are the same for Avatars
    default:
      return 'https://tpuntpdevstorage.blob.core.windows.net/staff-avatars/';
  }
};

export const apiUrl = () => {
  switch (getEnvironment()) {
    case environments.Localhost:
      return undefined;
    case environments.Production:
      return 'https://api.glide-platform.co.uk/api/';
    case environments.Test:
      return 'https://api.test.glide-platform.co.uk/api/';
    case environments.Development:
    default:
      return 'https://api.dev.glide-platform.co.uk/api/';
  }
};

export const glide2OfficeUrl = () => {
  switch (getEnvironment()) {
    case environments.Localhost:
      return 'http://localhost:3022/';
    case environments.Production:
      return 'https://office.glide-platform.co.uk/';
    case environments.Test:
      return 'https://office.test.glide-platform.co.uk/';
    case environments.Development:
    default:
      return 'https://office.dev.glide-platform.co.uk/';
  }
};
