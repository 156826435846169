import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { avatarUrl } from '../../config';
import { LoginContext } from '../../context/login';

const Avatar = ({ size, rounded, name, className, mediaClass, isExact, userId }) => {
  const classNames = ['avatar', `avatar-${size}`, className].join(' ');
  const mediaClasses = [rounded ? `rounded-${rounded}` : 'rounded', mediaClass].join(' ');
  const [inError, setInError] = useState(false);
  const { imageRandom } = useContext(LoginContext);

  const error = () => {
    setInError(true);
  };

  useEffect(() => {
    setInError(false);
  }, [imageRandom, userId]);

  const getAvatar = () => {
    if (userId && !inError) {
      return <img className={mediaClasses} src={`${avatarUrl()}${userId}.png?r=${imageRandom}`} alt={name} onError={error} />;
    }

    if (name) {
      return (
        <div className={`avatar-name ${mediaClasses}`} title={name}>
          <span>{isExact ? name : name.match(/\b\w/g).join('')}</span>
        </div>
      );
    }

    return <div className={`avatar-name ${mediaClasses}`} title={name} />;
  };

  return <div className={classNames}>{getAvatar()}</div>;
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['ListGroups.js', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
  rounded: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  mediaClass: PropTypes.string,
  isExact: PropTypes.bool,
  userId: PropTypes.string,
};

Avatar.defaultProps = {
  size: 'xl',
  rounded: 'circle',
  isExact: false,
};

export default Avatar;
