import axios from 'axios';

export const ImportFailuresGetList = async () => {
  try {
    const result = await axios({
      method: 'get',
      url: 'v1/ImportFailures',
      params: {},
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const ImportFailureClear = async (id, calendarId) => {
  try {
    const result = await axios({
      method: 'delete',
      url: 'v1/ImportFailure',
      params: { id, calendarId },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};
