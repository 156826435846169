import React from 'react';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { configureAxios } from './api/axiosHelper';
import Layout from './layouts/Layout';

configureAxios();

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ToastContainer />
      <Layout />
    </Router>
  );
};

export default App;
