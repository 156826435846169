import React, { useEffect } from 'react';
import { ImportFailuresGetList } from '../../api/import';

const Test = () => {
  useEffect(() => {
    const LoadData = async () => {
      var result = await ImportFailuresGetList();

      console.warn(result);
    };

    LoadData();
  });

  return <div>Help!</div>;
};

export default Test;
