import axios from 'axios';
import { today } from '../config';
import { formattedDate } from '../helpers/dateHelpers';

export const boardGet = async (date) => {
  date = formattedDate(date);

  try {
    const result = await axios({
      method: 'get',
      url: '/v1/Board',
      params: { date },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const boatDelete = async (id) => {
  try {
    const result = await axios({
      method: 'delete',
      url: 'v1/Boat',
      data: { id },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const boatStopSMS = async (id) => {
  try {
    const result = await axios({
      method: 'post',
      url: 'v1/Boat/StopSms',
      data: { id },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const apiBookingSMSNow = async (bookingId) => {
  try {
    const result = await axios({
      method: 'post',
      url: 'v1/Booking/SendSms',
      data: { bookingId },
    });

    return result.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const boatEdit = async (id, date, time, duration, lane, noOfSections, createdById, chauffeurId, notes) => {
  date = formattedDate(date);

  if (chauffeurId === '') {
    chauffeurId = null;
  }

  try {
    const result = await axios({
      method: 'post',
      url: 'v1/Boat',
      data: { id, date, time, duration, lane, noOfSections, createdById, chauffeurId, notes },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const boatGet = async (id) => {
  try {
    const result = await axios({
      method: 'get',
      url: 'v1/Boat',
      params: { id },
    });

    if (!result.data.notes) {
      result.data.notes = '';
    }

    if (!result.data.chauffeurId) {
      result.data.chauffeurId = '';
    }

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const bookingEdit = async (id, boatId, source, name, seatsBooked, seatsRequired, pricePaid, hasReservedFrontSection, notes, paymentMethod, isPaid, contactNumber, ticketCategoryId, happyToSitOpposite, email, resendConfirmation) => {
  const toutPrivate = seatsBooked.toString().includes('PVT');
  seatsBooked = seatsBooked.toString().replace('PVT', '');
  if (!seatsRequired) {
    seatsRequired = null;
  }

  try {
    const result = await axios({
      method: 'post',
      url: 'v1/Booking',
      data: { id, boatId, source, name, seatsBooked, seatsRequired, pricePaid, hasReservedFrontSection, notes, paymentMethod, isPaid, toutPrivate, contactNumber, ticketCategoryId, happyToSitOpposite, email, resendConfirmation },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const bookingUpdateContactNumber = async (id, contactNumber) => {
  try {
    const result = await axios({
      method: 'patch',
      url: 'v1/Booking/ContactNumber',
      data: { id, contactNumber },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const bookingPrivateEdit = async (
  bookingId,
  boatId,
  date,
  time,
  duration,
  lane,
  noOfSections,
  chauffeurId,
  source,
  name,
  ticketNumber,
  contactNumber,
  email,
  seatsBooked,
  isPaid,
  boatNotes,
  bookingNotes,
  paymentMethod,
  pricePaid,
  ticketCategoryId,
  happyToSitOpposite
) => {
  date = formattedDate(date);

  if (chauffeurId === '') {
    chauffeurId = null;
  }

  try {
    const result = await axios({
      method: 'post',
      url: 'v1/Booking/Private',
      data: { bookingId, boatId, date, time, duration, lane, noOfSections, chauffeurId, source, name, ticketNumber, contactNumber, email, seatsBooked, isPaid, boatNotes, bookingNotes, paymentMethod, pricePaid, ticketCategoryId, happyToSitOpposite },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const bookingDelete = async (id) => {
  try {
    const result = await axios({
      method: 'delete',
      url: 'v1/Booking',
      data: { id },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const bookingMove = async (bookingId, newBoatId) => {
  try {
    const result = await axios({
      method: 'patch',
      url: 'v1/Booking',
      data: { bookingId, newBoatId },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const bookingPrivateDelete = async (bookingId, boatId) => {
  try {
    const result = await axios({
      method: 'delete',
      url: 'v1/Booking/Private',
      data: { bookingId, boatId },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const bookingGet = async (id) => {
  try {
    const result = await axios({
      method: 'get',
      url: 'v1/Booking',
      params: { id },
    });

    if (result.data) {
      result.data.ticket = `${result.data.seatsBooked}/${result.data.seatsRequired || result.data.seatsBooked}`;

      if (!result.data.pricePaid) {
        result.data.pricePaid = '';
      }

      if (!result.data.ticketNumber) {
        result.data.ticketNumber = '';
      }

      if (!result.data.contactNumber) {
        result.data.contactNumber = '';
      }

      if (!result.data.email) {
        result.data.email = '';
      }

      if (result.data.toutPrivate) {
        if (result.data.seatsBooked === 6) {
          result.data.seatsBooked = 'PVT6';
        } else {
          result.data.seatsBooked = 'PVT12';
        }
      }
    }
    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const TicketCategoriesGetList = async (tourTypeId) => {
  try {
    const result = await axios({
      method: 'get',
      url: 'v1/TicketCategories',
      params: { tourTypeId },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const apiDayClose = async (closeTime) => {
  try {
    const result = await axios({
      method: 'post',
      url: 'v1/DayClose',
      data: {
        date: formattedDate(today()),
        closeTime,
      },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};
