import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, Navbar, NavbarToggler, NavItem, Nav } from 'reactstrap';
import AppContext from '../../context/AppContext';
import moment from 'moment';
import { MessageOfTheDayContext } from '../../context/messageOfTheDay';
import Logo from './Logo';
import ProfileDropdown from './ProfileDropdown';
import { LoginContext } from '../../context/login';

const NavbarTop = () => {
  const { showBurgerMenu, setShowBurgerMenu } = useContext(AppContext);
  const { today, viewingDate, viewingToday, back1Day, forward1Day, goToToday, isTout, anyDayClaim } = useContext(LoginContext);
  const { messageOfTheDay } = useContext(MessageOfTheDayContext);

  return (
    <Navbar light className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit" expand>
      <NavbarToggler onClick={() => setShowBurgerMenu(!showBurgerMenu)} id="burgerMenu" />
      <Logo at="navbar-top" width={40} id="topLogo" />
      <Collapse navbar>
        {messageOfTheDay && (
          <Nav navbar className="align-items-center d-none d-lg-block">
            <NavItem>
              <FontAwesomeIcon icon="bullhorn" size="lg" className="mr-1" /> {messageOfTheDay}
            </NavItem>
          </Nav>
        )}

        <Nav navbar className="align-items-center ml-auto">
          {anyDayClaim() && !isTout() ? (
            <>
              <div onClick={back1Day} className="date-nav">
                <FontAwesomeIcon icon="arrow-left" size="lg" className="mx-1 " />
              </div>
              {viewingToday() ? (
                <div>{moment(today()).format('Do MMM YYYY')}</div>
              ) : (
                <>
                  <div onClick={goToToday} className="date-nav">
                    <FontAwesomeIcon icon="home" size="lg" className="mx-1" />
                  </div>
                  <div className="text-warning">{moment(viewingDate).format('Do MMM YYYY')}</div>
                </>
              )}
              <div onClick={forward1Day} className="date-nav">
                <FontAwesomeIcon icon="arrow-right" size="lg" className="mx-1" />
              </div>
            </>
          ) : (
            <div>{moment(today()).format('Do MMM YYYY')}</div>
          )}

          <ProfileDropdown />
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarTop;
