export const dashboardRoutes = {
  name: 'Dashboard',
  to: '/',
  exact: true,
  icon: 'home',
  claims: ['SysAdmin', 'Dashboard'],
};

export const staffRoutes = {
  name: 'Staff',
  to: '/staff',
  exact: true,
  icon: 'users',
  claims: ['SysAdmin', 'UserAdmin', 'WorkRecordAdmin'],
};

export const checkoutRoutes = {
  name: 'Staff Checkout',
  to: '/checkout',
  exact: true,
  icon: 'house-night',
  claims: ['SysAdmin', 'WorkRecordAdmin'],
};

export const closeDayRoutes = {
  name: 'Close Day',
  to: '/close-day',
  exact: true,
  icon: 'cloud-moon-rain',
  claims: ['SysAdmin', 'CloseDay'],
};

export const workRecordRoutes = {
  name: 'Work Records',
  to: '/work-records',
  exact: true,
  icon: 'folders',
  claims: ['SysAdmin', 'WorkRecordAdmin'],
};

export const boardRoutes = {
  name: 'Board',
  to: '/board',
  exact: true,
  icon: 'chess-board',
  claims: ['SysAdmin', 'Board'],
};

export const userWorkRecordRoutes = {
  name: 'My Work Records',
  to: '/my-work-records',
  exact: true,
  icon: 'folder',
};

export const jobTypeRatesRoutes = {
  name: 'Staff Rates',
  to: '/jobTypeRates',
  exact: true,
  icon: 'pound-sign',
};

export const sourcesRoutes = {
  name: 'Sources',
  to: '/sources',
  exact: true,
  icon: 'tag',
  claims: ['SysAdmin', 'SourceAdmin'],
};

export const adjustRoutes = {
  name: 'Change Date',
  to: '/viewingDate',
  exact: true,
  icon: 'clock',
  claims: ['SysAdmin', 'AnyDay'],
};

export const reportRoutes = {
  name: 'Day Report',
  to: '/dayReport',
  exact: true,
  icon: 'chart-bar',
  claims: ['SysAdmin', 'DayReport'],
};

export const strideRoutes = {
  name: 'Stride',
  to: '/stride-bookings',
  icon: 'walking',
  claims: ['SysAdmin', 'StrideManager', 'StrideGuide', 'StrideTout'],
};

export const millPondRoutes = {
  name: 'Mill Pond',
  to: '/mill-pond-bookings',
  icon: 'rectangle-wide',
  claims: ['SysAdmin', 'MillPondManager', 'MillPondGuide', 'MillPondTout'],
};

export const glide2Routes = {
  name: 'Glide 2',
  to: '/glide2',
  icon: 'building',
  claims: ['SysAdmin', 'OfficeAdmin'],
};

export const logoutRoute = {
  name: 'Logout',
  to: '/authentication/logout',
  exact: true,
  icon: 'sign-out-alt',
};

export default [
  dashboardRoutes,
  boardRoutes,
  staffRoutes,
  checkoutRoutes,
  closeDayRoutes,
  workRecordRoutes,
  userWorkRecordRoutes,
  jobTypeRatesRoutes,
  sourcesRoutes,
  reportRoutes,
  adjustRoutes,
  strideRoutes,
  millPondRoutes,
  glide2Routes,
  logoutRoute,
];
