import React from 'react';
import { Card, CardBody } from 'reactstrap';
import useInterval from '../../hooks/useInterval';
import { today } from '../../config';
import { boardGet } from '../../api/board';

const Error500 = () => {
  useInterval(async () => {
    var result = await boardGet(today());

    if (result) {
      window.location.href = '/';
    }
  }, 15000);

  return (
    <Card className="text-center">
      <CardBody className="p-5">
        <h1>Connection Issue</h1>
        <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">Something went wrong!</p>
      </CardBody>
    </Card>
  );
};

export default Error500;
