import React, { useContext, useEffect } from 'react';
import AppContext from '../context/AppContext';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import { MessageOfTheDayProvider } from '../context/messageOfTheDay';
import { ReferenceDataProvider } from '../context/referenceData';
import loadable from '@loadable/component';
import { UserIsAuthenticated } from '../api/auth';
import { LoginContext } from '../context/login';
const DashboardRoutes = loadable(() => import('./DashboardRoutes'));

const DashboardLayout = () => {
  const { isFluid } = useContext(AppContext);
  const { userId } = useContext(LoginContext);
  const history = useHistory();

  useEffect(() => {
    if (!UserIsAuthenticated()) {
      history.push('/authentication/login');
    }
  }, [history]);

  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  if (!userId) {
    return null;
  }

  return (
    <ReferenceDataProvider>
      <MessageOfTheDayProvider>
        <div className={isFluid ? 'container-fluid' : 'container'}>
          <NavbarVertical />
          <div className="content">
            <NavbarTop />
            <Switch>
              <DashboardRoutes />
              <Redirect to="/errors/404" />
            </Switch>
            <Footer />
          </div>
        </div>
      </MessageOfTheDayProvider>
    </ReferenceDataProvider>
  );
};

export default DashboardLayout;
