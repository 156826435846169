import React, { createContext, useState, useEffect } from "react";
import { MessageOfTheDayGet, MessageOfTheDaySet } from '../../api/messageOfTheDay';

export const Context = createContext({});

export const Provider = ({children}) => {
  // Use State to keep the values
  const [messageOfTheDay, setMessageOfTheDay] = useState('');
  
  const setNewMessageOfTheDay = async(newMessageOfTheDay) => {
    await MessageOfTheDaySet(newMessageOfTheDay);

    setMessageOfTheDay(newMessageOfTheDay)
  };

  // Make the context object:
  const messageOfTheDayContext = {
    messageOfTheDay,
    setNewMessageOfTheDay,
  };

  useEffect(() => {
    const LoadData = async() => {
        var result = await MessageOfTheDayGet();

        setMessageOfTheDay(result);
    }

    LoadData();
  },[]);

  // pass the value in provider and return
  return <Context.Provider value={messageOfTheDayContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
