import moment from 'moment';

export const formattedDate = (date) => {
  if (!date) {
    return date;
  }

  if (typeof date === 'string') {
    const m = moment(date);

    return m.format('YYYY-MM-DD');
  } else {
    const m = moment([date.getFullYear(), date.getMonth(), date.getDate()]);

    return m.format('YYYY-MM-DD');
  }
};
