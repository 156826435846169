import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Nav, NavItem } from 'reactstrap';
import { NavLink, withRouter } from 'react-router-dom';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import { LoginContext } from '../../context/login';

const NavbarVerticalMenu = ({ routes, location, handleNavbarVerticalCollapse }) => {
  const { userClaims } = useContext(LoginContext);
  const [opened, setOpened] = useState(null);
  useEffect(() => {
    let openedDropdown = null;
    routes.forEach((route, index) => {
      if (location.pathname.indexOf(route.to) === 0) openedDropdown = index;
    });

    setOpened(openedDropdown);
    // eslint-disable-next-line
  }, []);

  const toggleOpened = (index) => setOpened(opened === index ? null : index);

  const claims = userClaims;

  return routes.map((route, index) => {
    if (route.claims) {
      if (route.claims.filter((x) => claims.includes(x)).length === 0) {
        return null;
      }
    }

    if (!route.children) {
      if (route.href) {
        return (
          <NavItem key={index}>
            <a className="nav-link" href={route.href} onClick={handleNavbarVerticalCollapse}>
              <NavbarVerticalMenuItem route={route} />
            </a>
          </NavItem>
        );
      } else {
        return (
          <NavItem key={index}>
            <NavLink className="nav-link" {...route} onClick={handleNavbarVerticalCollapse}>
              <NavbarVerticalMenuItem route={route} />
            </NavLink>
          </NavItem>
        );
      }
    }

    return (
      <NavItem key={index}>
        <NavLink to="#" onClick={() => toggleOpened(index)} className="nav-link dropdown-indicator" aria-expanded={opened === index}>
          <NavbarVerticalMenuItem route={route} />
        </NavLink>
        <Collapse isOpen={opened === index}>
          <Nav>
            <NavbarVerticalMenu routes={route.children} location={location} handleNavbarVerticalCollapse={handleNavbarVerticalCollapse} />
          </Nav>
        </Collapse>
      </NavItem>
    );
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(NavbarVerticalMenu);
