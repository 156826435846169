import axios from 'axios';
import { formattedDate } from '../helpers/dateHelpers';

export const JobTypesGetList = async (pickableOnly = true) => {
  try {
    const result = await axios({
      method: 'get',
      url: '/v1/JobTypes',
      params: {},
    });

    if (pickableOnly) {
      return result.data.filter((x) => x.pickable);
    }

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const JobTypeRatesGetList = async () => {
  try {
    const result = await axios({
      method: 'get',
      url: '/v1/JobTypeRates',
      params: {},
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const JobTypeRateSave = async (id, asOf, jobTypeId, level1, level2, level3, level4) => {
  asOf = formattedDate(asOf);
  level1 = level1 || null;
  level2 = level2 || null;
  level3 = level3 || null;
  level4 = level4 || null;

  try {
    const result = await axios({
      method: 'post',
      url: 'v1/JobTypeRate',
      data: { id, asOf, jobTypeId, level1, level2, level3, level4 },
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};
