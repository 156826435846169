import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import Avatar from '../common/Avatar';
import { LoginContext } from '../../context/login';

const ProfileDropdown = () => {
  const { userName, userId } = useContext(LoginContext)

  return (
      <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav className="pr-0">
        <Avatar name={userName} userId={userId} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem tag={Link} to="/authentication/logout">
            Logout
          </DropdownItem>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}


export default ProfileDropdown;
