import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { UserIsAuthenticated } from '../api/auth';
import Test from '../components/Testing/Test';
import { LoginProvider } from '../context/login';
import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';

const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));

const Layout = ({ location }) => {
  useEffect(() => {
    AuthBasicLayout.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/authentication" component={AuthBasicLayout} />
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/test" component={Test} />
        <Route
          render={() =>
            UserIsAuthenticated() ? (
              <LoginProvider>
                <DashboardLayout />
              </LoginProvider>
            ) : (
              <Redirect to="/authentication/login" />
            )
          }
        />
      </Switch>
    </Router>
  );
};

Layout.propTypes = { location: PropTypes.object.isRequired };

export default withRouter(Layout);
