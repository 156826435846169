import { apiUrl } from '../config';
import axios from 'axios';
import { Logout } from './auth';

export const configureAxios = () => {
  const BAD_REQUEST = 400;
  const UNAUTHORIZED = 401;
  const FORBIDDEN = 403;
  const INTERNAL_SERVER_ERROR = 500;
  const GATEWAY_TIMEOUT = 504;

  axios.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'application/json';
    config.baseURL = apiUrl();
    config.withCredentials = true;

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.error(error);

      if (!error.response) {
        window.location.href = '/errors/500';
        return Promise.reject(error);
      }

      const { status } = error.response;

      if (status === BAD_REQUEST) {
        console.error(error.response);
      }

      if (status === INTERNAL_SERVER_ERROR) {
        window.location.href = '/errors/500';
        return Promise.reject(error);
      }

      if (status === GATEWAY_TIMEOUT) {
        //TODO: Better Gateway Timeout handling
        window.location.href = '/errors/500';
        return Promise.reject(error);
      }

      if (status === UNAUTHORIZED || status === FORBIDDEN) {
        try {
          await Logout();
        } catch {
          //Ignore, it's just a cleanup task
        }

        window.location.href = '/authentication/login';
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );
};
