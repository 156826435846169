import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import { authExpiryCookieName } from '../config';

export const Login = async (email, password) => {
  const result = await axios({
    method: 'post',
    url: '/v1/Login',
    data: {
      EmailAddress: email,
      Password: password,
    },
  });

  const data = result.data;

  return data.successful;
};

export const Logout = async () => {
  await axios({
    method: 'post',
    url: '/v1/Logout',
  });
};

export const CurrentUserDetails = async () => {
  var result = await axios({
    method: 'get',
    url: '/v1/User/Current',
  });

  return result.data;
};

export const UserIsAuthenticated = () => {
  const now = moment();
  const date = Cookies.get(authExpiryCookieName());
  const expiry = moment(date);

  return date && expiry.isAfter(now);
};
